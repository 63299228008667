import React, { useState } from "react";
import { Checkbox } from "antd";
import styled from "styled-components";

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownButton = styled.button`
  width: 140px;
  height: 38px;
  border-radius: 6px;
  background-color: #3f2faa;
  border: none;
  color: #a498ed;
  padding: 6px 20px 6px 0px;
  cursor: pointer;
  position: relative;

  &::after {
    content: "\\25BC";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%) ${props => (props.isOpen ? "rotate(0deg)" : "rotate(270deg)")};
    transition: transform 0.3s;
  }

  &:hover::after {
    color: #fff;
  }
`;

const DropdownOptions = styled.div`
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  background-color: #3f2faa;
  border-top: none;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  z-index: 1;
`;

const OptionLabel = styled.label`
  display: block;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: transparent;
    border-color: #6c47ff;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6c47ff;
    border-color: transparent;
  }

  & > label {
    color: #a498ed;
    text-transform: capitalize;
  }
`;

const CheckboxDropdown = ({
  options = [],
  title = "",
  selectedValues = [],
  onSelectedValuesChange,
  dropdownBtnStyles = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  /**
   * The function `handleCheckboxChange` toggles the selection of a value in an array and updates the
   * selected values.
   */
  const handleCheckboxChange = value => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter(val => val !== value)
      : [...selectedValues, value];
    onSelectedValuesChange(newSelectedValues);
  };

  return (
    <DropdownContainer>
      <DropdownButton type="button" isOpen={isOpen} onClick={toggleDropdown} style={dropdownBtnStyles}>
        {title}
      </DropdownButton>
      {isOpen && (
        <DropdownOptions>
          {options.map(option => (
            <OptionLabel key={option.value}>
              <CustomCheckbox
                value={option.value}
                checked={selectedValues.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}
              />
              {option.name}
            </OptionLabel>
          ))}
        </DropdownOptions>
      )}
    </DropdownContainer>
  );
};

export default CheckboxDropdown;
