import React from "react";
import * as S from "../styles";
import LeaderboardRow from "./LeaderboardRow";

const LeaderboardComponent = props => {
  let {
    displayData = [],
    leaderboardData = [],
    joinMode,
    isRoundRobin,
    setPlayersToMoveGroup,
    setPlayerToSwapGroup,
  } = props;

  if (!Array.isArray(displayData) || displayData.length < 1) {
    displayData = [{ dataKey: "score", dataOrder: 1, dataTitle: "Score" }];
  }
  return (
    <div>
      <S.LeaderboardSectionContainer>
        <S.LeaderboardSectionInnerContainer>
          {leaderboardData.length > 0 ? (
            <S.LeaderboardHeader dynamicColumnsCount={displayData?.length}>
              <div>Ranking</div>
              <div>Nickname</div>
              {displayData?.map(dataItem => (
                <div>{dataItem.dataTitle}</div>
              ))}
              <div></div>
            </S.LeaderboardHeader>
          ) : null}
          <S.LeaderboardRowsContainer>
            {leaderboardData?.map((rowData, index) => (
              <LeaderboardRow
                key={rowData.id}
                rank={index + 1}
                {...rowData}
                displayData={displayData}
                joinMode={joinMode}
                isRoundRobin={isRoundRobin}
                setPlayersToMoveGroup={setPlayersToMoveGroup}
                setPlayerToSwapGroup={setPlayerToSwapGroup}
                rowData={rowData}
              />
            ))}
          </S.LeaderboardRowsContainer>
        </S.LeaderboardSectionInnerContainer>
      </S.LeaderboardSectionContainer>
    </div>
  );
};

export default LeaderboardComponent;
